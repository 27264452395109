


















































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import ProcedureStatusGroup from "./ProcedureStatusGroup.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import PatientCard from "./ProcedureCard.vue";
import FilterByPatient from "./FilterByPatient.vue";

import values from "lodash/values";
import { DropResult, applyDrag } from "./dragDropUtils";
import { mapActions, mapGetters, mapState } from "vuex";
import { ProcedureStatus } from "@/models/Patient";
import { getAPI } from "@/api/axios-base";
import PpsSales from "../PPS/PpsSales.vue";
import moment from "moment";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";

export default Vue.extend({
  name: "dashboard-procedure-status-list",
  components: {
    ProcedureStatusGroup,
    PatientCard,
    Container,
    Draggable,
    FilterByPatient,
    PpsSales,
    MaDatePicker,
  },
  data() {
    return {
      tab: null,
      cards: [],
      loading: false,
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },

      req: {
        patientUuid: "",
        employeeUuid: "",
        procedure_type: "",
        surgicalDate: {
          date1: "",
          date2: "",
        },
        createdAtRange: {
          date1: "",
          date2: "",
        },
      },
      createDateFrom: null,
      createDateTo: null,
      surgicalDateFrom: null,
      surgicalDateTo: null,

      items: [
        { label: "All", value: "" },
        { label: "Surgerys", value: "Surgery" },
        { label: "Treatments", value: "Treatment" },
      ],
    };
  },
  computed: {
    ...mapGetters("crmDashboardModule", ["getPatientsByStatus"]),
    ...mapGetters(["getCoordinatorN", "getpatientN", "getviewPps"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers", "coordinators"]),
    style(): any {
      const style = {
        height: `${
          this.$vuetify.breakpoint.height -
          64 * 3 -
          (this.$vuetify.breakpoint.lgAndUp ? 25 : 15)
        }px !important`,
      };

      return style;
    },
  },
  watch: {
    tab: {
      handler() {
        localStorage.setItem("tabdashboard", (this as any).tab);
      },
    },

    createDateFrom(val) {
      if (
        val !== null &&
        this.createDateTo !== null &&
        this.createDateTo !== ""
      ) {
        this.init();
      }
    },
    createDateTo(val) {
      if (
        val !== null &&
        this.createDateFrom !== null &&
        this.createDateFrom !== ""
      ) {
        this.init();
      }
    },
    surgicalDateFrom(val) {
      if (
        val !== null &&
        this.surgicalDateTo !== null &&
        this.surgicalDateTo !== ""
      ) {
        this.init();
      }
    },
    surgicalDateTo(val) {
      if (
        val !== null &&
        this.surgicalDateFrom !== null &&
        this.surgicalDateFrom !== ""
      ) {
        this.init();
      }
    },
  },
  async mounted() {
    (this as any).tab = localStorage.getItem("tabdashboard")
      ? localStorage.getItem("tabdashboard")
      : "tab-1";
    this.init();
    this.loading = true;
    await this.actGetEmployees();
    await this.actListPatientsFilter();
    this.loading = false;
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListPatientsFilter",
      "actUpdatePatientStatus",
    ]),

    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetCoordinators",
    ]),
    ...mapActions("crmDashboardModule", [
      "actProcedures",
      "actUpdateProcedureStatus",
      "actFilterDashboardProcedure",
    ]),
    onCardDrop(columnId: any, dropResult: DropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const cards: any = values(Object.assign({}, this.cards));
        const column = this.cards.filter(
          (card: any) => card.id === columnId
        )[0];
        const columnIndex = cards.indexOf(column);
        const newColumn: any = Object.assign({}, column);

        newColumn.children = applyDrag(newColumn.children, dropResult);
        cards.splice(columnIndex, 1, newColumn);
        this.cards = cards;

        // Update only if is a valid movement
        if (dropResult.addedIndex != null && dropResult.removedIndex == null) {
          this.loading = true;

          this.updateProcedureStatus(dropResult.payload.id, columnId)
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    // Get the data from childs
    getCardPayload(columnId: number | string) {
      return (index: number) => {
        return (this as any).cards.filter((p: any) => p.id === columnId)[0]
          .children[index];
      };
    },
    dragStart() {
      // console.log("drag started");
    },
    log(...params: any) {
      // console.log(...params);
    },
    async updateProcedureStatus(id: string, status: any): Promise<any> {
      this.loading = false;
      this.actUpdateProcedureStatus({
        id: id,
        status: status,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async init() {
      this.loading = true;

      let body: any = Object.assign({}, this.req);
      if (this.createDateFrom == null || this.createDateTo == null) {
        delete body["createdAtRange"];
      } else {
        body.createdAtRange = {
          date1: moment(this.createDateFrom).toISOString(),
          date2: moment(this.createDateTo).toISOString(),
        };
      }

      if (this.surgicalDateFrom == null || this.surgicalDateTo == null) {
        delete body["surgicalDate"];
      } else {
        body.surgicalDate = {
          date1: moment(this.surgicalDateFrom).toISOString(),
          date2: moment(this.surgicalDateTo).toISOString(),
        };
      }

      /*     body.createdAtRange = {
        date1: moment().startOf("year").toISOString(),
        date2: moment().toISOString(),
      };
      body.surgicalDate = {
        date1: moment().startOf("year").toISOString(),
        date2: moment().toISOString(),
      }; */

      body = this.cleanNull(body);
      this.actFilterDashboardProcedure(body).then((procedures: any) => {
        this.loading = false;
        this.cards = procedures;
      });
      document
        .getElementsByTagName("html")[0]
        .setAttribute("style", "overflow-y: hidden;");
    },
    cleanNull(obj: any) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    resetFilter(type: string) {
      switch (type) {
        case "create":
          this.req.createdAtRange.date1 = "";
          this.req.createdAtRange.date2 = "";
          this.createDateFrom = null;
          this.createDateTo = null;
          break;
        case "procedure":
          this.req.surgicalDate.date1 = "";
          this.req.surgicalDate.date2 = "";
          this.surgicalDateFrom = null;
          this.surgicalDateTo = null;
          break;
        case "patient":
          this.req.patientUuid = "";
          break;
        case "employee":
          this.req.employeeUuid = "";

          break;

        default:
          this.req = {
            patientUuid: "",
            employeeUuid: "",
            procedure_type: "",
            surgicalDate: {
              date1: "",
              date2: "",
            },
            createdAtRange: {
              date1: "",
              date2: "",
            },
          };

          this.createDateFrom = null;
          this.createDateTo = null;
          this.surgicalDateFrom = null;
          this.surgicalDateTo = null;
          break;
      }
      this.init();
    },
  },

  beforeDestroy() {
    document.getElementsByTagName("html")[0].setAttribute("style", "");
  },
});
