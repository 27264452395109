




















































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "filter-by-patient",
  data() {
    return {
      loading: false,
      req: {
        patientUuid: "",
        employeeUuid: "",
        procedure_type: "",
        surgicalDate: {
          date1: "",
          date2: "",
        },
        createdAtRange: {
          date1: "",
          date2: "",
        },
      },
      filters: "All",

      items: [
        { label: "All", value: "" },
        { label: "Surgerys", value: "Surgery" },
        { label: "Treatments", value: "Treatment" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getpatientN"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers", "coordinators"]),
  },
  async mounted() {
    this.loading = true;
    await this.actGetCoordinators();
    await this.actListPatientsFilter();
    this.loading = false;
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetCoordinators",
    ]),
    ...mapActions("crmDashboardModule", ["actFilterDashboardProcedure"]),
    onChange() {
      this.loading = true;

      let body = Object.assign({}, this.req);
      /*    body.createdAtRange = {
        date1: moment().startOf("year").toISOString(),
        date2: moment().toISOString(),
      };
      body.surgicalDate = {
        date1: moment().startOf("year").toISOString(),
        date2: moment().toISOString(),
      }; */

      body = this.cleanNull(body);
      this.actFilterDashboardProcedure(body).then((procedures: any) => {
        this.loading = false;
        this.$emit("procedures", procedures);
      });
    },

    cleanNull(obj: any) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    resetFilter() {
      this.req.patientUuid = "";
      this.req.employeeUuid = "";
      this.filters = "All";
      this.$emit("reset");
    },
  },
});
