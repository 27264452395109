











/**
 * Container for patients cards
 */
import Vue from "vue";

export default Vue.extend({
  name: "procedure-status-group",
  props: {
    title: {
      type: String,
      default: () => "Title",
    },
    cant: Number,
    color: String,
  },
});
